import React from "react";
import { Image, Card, Carousel, Button  } from "react-bootstrap";
import aboutUsImage from "../../assets/images/AboutUs.jpg"
import "../../style/AboutUs.css";
import aboutUsData from "../../assets/json/AboutUs.json"
import "../../style/AboutUs.css"
import CarouselComponent from "../commons/CarouselComponent";


function About() {

	return (
		<section>
            {/*About us title & image */}
            <div className="about-us-header">
                <p className="section-header-title text-center p-5">Know a bit more <span className='tg-blue-colour'>about us ...</span></p>
            </div> 
            <div className="p-3">
                { 
                    aboutUsData && aboutUsData["ourStory"].map((ourStory, index) => <div className="global-container" key={ourStory.index}>
			            <div className={`row p-3 ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`} data-aos={index % 2 ==0 ? 'fade-right' : 'fade-left'}>
			                <div className={`col-md-7 col-sm-12 text-center ${index % 2 === 0 ? 'fade-right' : 'fade-left'}`}>
			                    <Image
			                        src={require(`../../assets/images/${ourStory.image}`)}
			                        className="our-story-image"
			                    ></Image>
			                </div>
			                <div className={`col-md-5 col-sm-12 our-story-content ${index % 2 === 0 ? 'fade-left' : 'fade-right'}`}>
			                    <div className={`centered-content-${index % 2 === 0 ? 'left' : 'right'}`}>
			                        <h1 className="global-title"><span className='tg-blue-colour'>{ourStory.name}</span></h1>
			                        <p>{ourStory.description}</p>
			                    </div>
			                </div>
			            </div>
			        </div>)
                }
            </div>
            <div >
          			<div className="global-container">
                  <div className='text-center m-3'>
                    <p className="section-header-title text-center p-1">What makes <span className='tg-blue-colour'>us best</span></p>
                  </div>
                  <section className='mt-3 '>
                    <div className='container flex flex-col justify-center justify-between'>
                      <div className='row justify-content-center mx-auto mb-3'>
                        {
                          aboutUsData['whatmakesusbest'].map(value => {
                            return(
                              <div key={value.id} className='d-flex mt-4 flex-col col-sm-3'>
                                <div className="what-makes-us-best-card" data-aos='fade-top'>
                              {/* < div>
                                <img src={value.img} alt="Card image cap" />
                              </div> */}
                              <div className="card-body">
                                <span>
                                  <h5 className="font-weight-bold card-title" style={{fontWeight:"bold"}}>{value.title}</h5>
                                </span>
                                <p className="card-text pt-3">{value.description}</p>
                              </div>
                            </div>
                          </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </section>
                </div>

                {/* Commenting - About our team
                <div className="text-center p-3">
                  <p className="global-title text-center">Meet our <span className='tg-blue-colour'>talented team</span></p>
                </div>
                <CarouselComponent data={aboutUsData.team} className='pt-1'/>
                */}
            </div>


		</section>
		)

}


export default About;