import React from "react";

import {  Button } from "react-bootstrap";

const ButtonsTabComponent = ({selectedValue, data, getParameter, onClickMethod}) => {

	return (
		<div className='row'>
	        {data.map((item, index) => (
	        	<div className='col-md-6 col-lg-3 col-sm-12 pt-3'>
			          <Button
			            key={index}
			            onClick={() => onClickMethod(item)}
			            variant={
			              selectedValue === item ? "primary" : "outline-primary"
			            }
			            className="course-button w-100"
			          >
			            {item[getParameter]}
			          </Button>
	          </div>
	        ))}
	    </div>
	)
};

export default ButtonsTabComponent;