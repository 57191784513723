import React, {useEffect,useState} from "react";
import {Button, Dropdown} from "react-bootstrap";	
import DropdownComponent from "../../components/commons/DropdownComponent";
import ButtonsTabComponent from "../../components/commons/ButtonsTabComponent";

function MicroService({service}) {

    const microserviceNames = service.microservicesOffered.map((microservice) => microservice.name).join('\n');
    const [currentMicroserviceIndex, setCurrentMicroserviceIndex] = useState(0);

    const [selectedMicroService, setSelectedMicroService] = useState(service['microservicesOffered'][0]);
    const handleMicroServiceClick = (microService) => {
        setSelectedMicroService(microService);
    };


    useEffect(() => {
        const interval = setInterval(() => {
        setCurrentMicroserviceIndex((prevIndex) =>
            prevIndex === service.microservicesOffered.length - 1 ? 0 : prevIndex + 1
        );
        }, 2250); //for microservices animation

        return () => clearInterval(interval);
    }, []);

	return (
		<section>
			<div className='grey-background'>
				<div className="global-container justify-content-center pt-5 pb-1 d-none d-md-block">
		            <div className="course-modules">
		                <p className="microservice-animation">
		                    Pick and integrate with our - <strong className="slide-in pl-3">{service.microservicesOffered[currentMicroserviceIndex].name}</strong>
		                </p>
		            </div>
		        </div>  
	        </div>             

	        {/*buttons*/}
	        <div
	        >
	        	<div className='grey-background'>
		            <div className="global-container module-buttons p-2 row d-none d-md-block">
			            <ButtonsTabComponent 
			                selectedValue={selectedMicroService}
			                data={service["microservicesOffered"]}
			                getParameter={'name'}
			                onClickMethod={handleMicroServiceClick}
			                className="p-2 m-1"
		            	/>
		            </div>
		        </div>


		        <div className='grey-background'>
		          {/* Dropdown - For small devices - courses */}
		          <div className="col-md-12 text-center d-md-none p-3">
		          	<DropdownComponent 
		          		selectedValue={selectedMicroService} 
		          		dropdownData={service["microservicesOffered"]} 
		          		getParameter={'name'} 
		          		onClickMethod={handleMicroServiceClick} 
		          		color={'primary'}
		          	/>
		          </div>
		        </div>


	            {/*description*/}
	            {
	                selectedMicroService && 
	                <div className='grey-background ps-3 pe-3'>
		                <div className="global-container course-modules">
		                    <div className="row" >
		                        <div className="col-md-6 d-flex flex-column align-items-center justify-content-center" key={selectedMicroService.name}>
		                            <h4 >{selectedMicroService.description}</h4>
		                        </div>
		                        <div className="col-md-6">
		                            { <img src={require(`../../assets/images/${selectedMicroService['image']}`)} alt={selectedMicroService} className='microservice-image'/> }
		                        </div>
		                    </div>
		                </div>
		            </div>
		            
	            }


	            {selectedMicroService && 
	                <>


	                	{/*advantages*/}
	                    <div className=" pt-3 pb-3">
	                        <div className="global-container services-offered row pt-5 pl-2 pr-2">
	                            <p className="section-header-title text-center">Advantages of <span className='tg-blue-colour'>our microservices</span></p> 
	                            {service.advantages.map((advantage, index) => (
	                                <div className="col-md-3 pt-4" data-aos="fade 2s" key={index}>
	                                    <div className="course-card p-4 text-center"  > 
	                                        <img src={require(`../../assets/images/${advantage.image}`)} alt="stack"></img>
	                                    </div>
	                                    <div className="services-offered-card-heading text-center p-4 pt-2">
	                                    {advantage["name"]}
	                                    </div>
	                                </div>
	                            ))}  
	                        </div>

	                        {/*highlights*/}
	                    	<div className="global-container services-offered row pt-5 pl-2 pr-2">
	                            <p className="section-header-title text-center">Highlights of <span className='tg-blue-colour'>our microservices</span></p> 
	                            {service.techHighlights.map((highlight, index) => (
	                                <div className="services-offered-card pt-4 pb-4  col-md-4 justify-content-center" key={index}>
	                                    <div className="text-center">
	                                        <img className="services-offered-image" src={require(`../../assets/images/${highlight.image}`)} alt="service" data-aos="fade-right"></img>
	                                    </div>
	                                    <div className="course-card services-offered-card-heading p-2 text-center " data-aos="fade-left"> 
	                                        {highlight["name"]}
	                                    </div>
	                                    <div className="services-offered-description text-center ">
	                                        <div>
	                                            <p className="text-center" data-aos="fade-left">{highlight["description"]}</p>
	                                        </div>
	                                    </div>
	                                </div>))}
	                    	</div>
	                    </div>
	                </>
	            }
	        </div>
	    </section>
	)
	
};


export default MicroService;