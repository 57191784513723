import React, { useEffect, useState } from "react";
import {Dropdown} from "react-bootstrap";

import DropdownComponent from "../../components/commons/DropdownComponent";

function WebAndMobileAppService({service})  {


    {/**Application-cards */}
    const [enlarged, setEnlarged] = useState([true, ...Array(service["applicationServicesOffered"].length-1).fill(false)]);
    const [selectedService, setSelectedService] = useState(0);

    const toggleEnlarged = (index) => {
        if (index === selectedService) {
        return; // Do nothing if the clicked card is already enlarged
        }

        const updatedEnlarged = Array(service["applicationServicesOffered"].length).fill(false);
        updatedEnlarged[index] = true; // Enlarge the clicked card
        setEnlarged(updatedEnlarged);

        if (selectedService !== null) {
        updatedEnlarged[selectedService] = false; // Make the previously enlarged card normal
        setEnlarged(updatedEnlarged);
        }

        setSelectedService(index);
    };

    const handleWebAndMobileAppClick = (application) => {
    	setSelectedService(application.id);
    };

    useEffect(() => {
        // initially when no card is selected)
        if (selectedService === null) {
          const firstCardIndex = enlarged.findIndex((isEnlarged) => isEnlarged);
          setSelectedService(firstCardIndex);
        }
      }, [enlarged, selectedService]);

	return (                                       
            <section className='grey-background'>
                <div>
                    <div className="grey-background d-none d-md-block "></div> {/*semi grey background behind app cards*/}
                	{/* enlarged cards for desktop view*/}
                    <div className="global-container application-cards-menu d-none d-md-block p-3 pt-5 grey-background"> 
                        <div className="row pt-5 pb-5">
                            {service.applicationServicesOffered.map((appService) => (
                                <div className={"align-items-center justify-content-center col-md-" + 12/ (service["applicationServicesOffered"].length)} key={appService.id}>
                                    
                                    <div
                                        className={`application-card card ${enlarged[appService.id] ? 'enlarged' : ''} pt-5 pb-3 d-flex flex-column align-items-center justify-content-center`}
                                        onClick={() => toggleEnlarged(appService.id)}
                                    >
                                        <img src={require(`../../assets/images/${appService['image']}`)} alt="" className="app-card-image"/>
                                        <h4 className="pt-3 pb-3">{appService.name}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                    {/* Dropdown menu for mobile device - web and mobile app*/}
		          	<div className="col-md-12 text-center p-3 d-md-none">
			          	<DropdownComponent 
			          		selectedValue={service.applicationServicesOffered[selectedService]} 
			          		dropdownData={service["applicationServicesOffered"]} 
			          		getParameter={'name'} 
			          		onClickMethod={handleWebAndMobileAppClick} 
			          		color={'primary'}
			          	/>
		          	</div>
                    
                    <div className="global-container">
                        {selectedService !== null && (
                            <>
		                        <div className="service-details pt-3 row">
		                                                                                                                       
		                            <p className="section-header-title text-center">Tech stacks <span className='tg-blue-colour'>we use</span></p> 
		                            <h5 className="p-3 text-center">{service.applicationServicesOffered[selectedService].description}</h5>       
		                            {service.applicationServicesOffered[selectedService].techStacks.map((techStack, index) => (
		                                
		                                    <div className="col-md-3 pt-4" data-aos="fade 2s" key={index}>
		                                        <div className="course-card p-4 text-center"> 
		                                            <img src={require(`../../assets/images/${techStack.image}`)} alt="stack"></img>
		                                            
		                                        
		                                        </div>
		                                        <div className="services-offered-card-heading text-center p-4 pt-2">
		                                        {techStack["stackName"]}

		                                        </div>
		                                    </div>
		                                    
		                                
		                            ))}  

		                        </div>
		                        <div className="services-offered row pt-5 pl-2 pr-2">
		                            <p className="section-header-title  text-center">Services <span className='tg-blue-colour'>we offer</span></p> 
		                            {service.applicationServicesOffered[selectedService].services.map((serviceItem, index) => (
		                                <div className="services-offered-card pt-4  col-md-4 justify-content-center" key={index}>
		                                    <div className="text-center">
		                                        <img className="services-offered-image" src={require(`../../assets/images/${serviceItem.image}`)} alt="service" data-aos="fade-right"></img>
		                                    </div>
		                                    <div className="course-card services-offered-card-heading p-4 text-center" data-aos="fade-left"> 
		                                        {serviceItem["name"]}
		                                    </div>
		                                    <div className="services-offered-description text-center ">
		                                        <div>
		                                            <p className="text-center" data-aos="fade-left">{serviceItem["details"]}</p>
		                                            </div>
		                                        
		                                    </div>
		                                </div>))}
		                        </div>
                        	</>
                        )}
                    </div>

                </div>
            </section>           
		)
	
};

export default WebAndMobileAppService;
