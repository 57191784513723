import React, { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import "../../style/Commons/Carousel.css";


const TestimonialComponent = ({data}) => {

  const [paginationItems, setPaginationItems] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [feedbacks, setFeedBacks] = useState([]);
  const [noOfCards, setNoOfCards] = useState(2);



  const changePage = (toPage) => {
    let startIndex = ((toPage - 1) * noOfCards);
    setActivePage(toPage);
    setFeedBacks(data.slice(startIndex, startIndex + noOfCards));
  }

  useEffect(() => {
    const updateNoOfCards = () => {
      const newNoOfCards = window.innerWidth < 762 ? 1 : 2; //write better logic for mobile view
      setNoOfCards(newNoOfCards);

      const detailsCount = data.length;
      let temp = parseInt(detailsCount / newNoOfCards) + ((detailsCount % newNoOfCards) > 0 ? 1 : 0);
      const totalCount = temp > 1 ? temp : 0;
      let arr = [];

      for (let i = 1; i <= totalCount; i++) {
        arr.push(i);
      }
      setPaginationItems(arr);
      changePage(1);
      setFeedBacks(data.slice(0, newNoOfCards)); //revisit
    };

    updateNoOfCards();

    window.addEventListener('resize', updateNoOfCards);

    return () => {
      window.removeEventListener('resize', updateNoOfCards);
    };
  }, [data]);


  return (
    
        <section className="carousel-container pb-2">
            <div className=''>
            <div className='row mx-auto'>
              {
                feedbacks.map(item => {
                  return (
                   <div className='col-md-6 col-sm-12' key={item.id}>
                <div className="carousel-card-testimonial" data-aos="fade-left">
                  <div className='row'>
                    <div className='col-md-3'>
                      <img src={require(`../../assets/images/${item.image}`)} alt={"Image of " + item.name} className='carousel-card-img'></img>
                    </div>
                    <div className='col-md-5 carousel-testimonial-header pt-3'>
                     <p className='testimonial-name pt-2 pl-0  ml-0'>{item.name}</p>
                     <p className='pt-2' style={{fontSize: "20px"}}>Used {item.usedService} service</p>
                    </div>

                     { item.companyLogo &&
                    <div className='col-md-4 instructor-company'>

                      <img src={require(`../../assets/images/${item.companyLogo}`)} alt='logo' className='instructor-company-img'></img>
                    
                    </div>
                  }
                  </div>
                  <div className='row'>
                    {item.feedback && <p className='carousel-card-p-text pl-4 pr-4 pt-1'><q>{item.feedback}</q></p>}
                  </div>
                  
                  {item.rating && 
                    <div className='row'>
                      <p className='rating-desc'>Rating :  <span style={{color: "#ffcc00", fontWeight: "bold"}}>{item.rating}</span></p>
                    </div>
                  }
                </div>
              </div>
                  )
                }
                )
              }
              <div className='d-flex justify-content-center pt-2'>
                <Pagination>
                  {
                    paginationItems.map(pageNum => <Pagination.Item onClick={() => changePage(pageNum)} key={pageNum} active={pageNum == activePage}>
                      {pageNum}
                    </Pagination.Item>)
                  }
                </Pagination>
              </div>
            </div>
          </div>
        </section>


    
  );
};

export default TestimonialComponent;
