import React from "react";
import "../../style/Home.css"
import homeImage from "../../assets/images/homePageImage.svg";
import {Image} from 'react-bootstrap';
import homePageDetails from "../../assets/json/Home.json";
import TestimonialComponent from "../../components/commons/TestimonialsComponent";
import ServicesComponent from "../../components/commons/ServicesComponent";
import aboutUsImage from "../../assets/images/AboutUs.svg";
import aboutUsData from "../../assets/json/AboutUs.json";

function Home() {


    const Clients = ({ clientsList }) => (
      <div className="clients-slider">
        {clientsList.map((client, index) => (
          <img key={index} src={require(`../../assets/images/${client.image}`)} alt={`Logo ${index}`} />
        ))}
      </div>
    );

    const historyItem = aboutUsData['ourStory'].find(item => item.id === 'history');

    return(
            <>
                {/*TechGanah & GIF */}
                <section className="title-and-gif pb-5">
                    <div className="global-container p-3">
                        <div className="row align-items-center">
                            <div className="col-md-6 pt-5">
                                <div className="text-center" data-aos='fade-right'>
                                    <h1 className="techganah-title tg-blue-colour">{"</TechGanah>"}</h1>
                                    <p className="tag-line">Prepare for an enduring success</p>
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="text-center">
                                    <Image 
                                    className="section-gif d-block w-100"
                                    rounded
                                    src={homeImage}
                                    data-aos="fade-left"
                                    ></Image>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*About Us */}
                <section className='grey-background pt-3 pb-3'>
                    <div className='global-container'>
                        <p className="section-header-title text-center">About <span className='tg-blue-colour'>us</span></p>
                        <div className='row p-3'>
                            <div className='col-md-7 col-sm-12 text-center'>
                                <Image
                                    src={aboutUsImage}
                                    className="our-story-image"
                                ></Image>
                            </div>
                            <div className='col-md-5 col-sm-12 our-story-content'>
                                <div>
                                    <p>{historyItem.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*Our Services */}
                <section className="global-container p-3">
                    <div>
                        <p className="section-header-title text-center">Explore our wide <span className='tg-blue-colour'>range of services</span></p>
                    </div>
                    <div className="section-title text-center mb-4">
                        <p className="text-muted para-desc mx-auto mb-0">Whether you are a 6-month-old Start-up or a 30-year-old Titan looking for a solution for complex challenges, with a team of highly skilled tech professionals and strategic alliances with technology merchants, Techganah offers a wide range of services designed to help your business overgrow more efficiently.</p>
                    </div>
                    <ServicesComponent servicesList={homePageDetails["servicesShortDescription"]}/>
                </section>


                {/* Comenting Our clients              
                <section className="home-our-clients pt-5 pb-5">
                    <div className="global-container">
                    <div className="">
                    <p className="section-header-title text-center">Our <span className='tg-blue-colour'>happy clients</span></p>
                    </div>

                    <div className="clients">
                        <Clients clientsList={homePageDetails["clients"]} />
                        <Clients clientsList={homePageDetails["clients"]} />
                    </div>
                    </div>
                </section>
                */}


                {/* Testimonials*/}
                <section className="pb-3">
                    <div className="global-container">
                        <div className="row p-3">
                        <p className="section-header-title text-center">Hear from<span className='tg-blue-colour'> our customers</span></p>
                        </div>
                        {/** Innovative Carousel*/}
                        <div>
                            <TestimonialComponent data={homePageDetails["testimonials"]}/>
                        </div>
                    </div>
                </section>
            </>
    );
}

export default Home;