import React from 'react'
import footerDetails from '../../assets/json/Footer.json'
import '../../index.css'
import "../../style/Footer.css"

const Footer = () => {
    return (
        <section className='footer'>
            <footer className="text-center text-lg-start">
                <div className="container p-4 pb-0">
                    <section>
                        <div className="row">
                            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                                <h6 className="text-uppercase mb-4 font-weight-bold" style={{fontWeight:"bold"}}>
                                    {footerDetails['basicInfo'].name}
                                </h6>
                                <p>
                                    {footerDetails['basicInfo'].description}
                                </p>
                            </div>

                            <div className="col-md-4 d-none d-md-block mx-auto mt-3">
                                <h6 className="text-uppercase mb-3 text-center" style={{fontWeight:"bold"}}>Services</h6>
                                <div className='container-fluid'>
                                    <div className='row mx-auto'>
                                        {
                                            [0,1].map(ele => <div key={ele} className='col-md-6 mx-auto'>
                                                {
                                                    footerDetails['services'].slice((ele * 4), 4 + (ele * 4)).map(service => <p key={service.id}>
                                                        <a className="text-decoration-none text-white footer-anchor" href={service['hrefLink']}>{service['name']}</a>
                                                    </p>
                                                    )
                                                }
                                            </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            <hr className="w-100 clearfix d-md-none" />

                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                                <h6 className="text-uppercase mb-4 font-weight-bold" style={{fontWeight:"bold"}}>
                                    Useful links
                                </h6>
                                {footerDetails['usefulLinks'].map(link => <p key={link.id}>
                                    <a className="text-decoration-none text-white footer-anchor" href= {link.link}>{link.name}</a>
                                </p>)}
                            </div>

                            <hr className="w-100 clearfix d-md-none" />

                            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                                <h6 className="text-uppercase mb-4 font-weight-bold" style={{fontWeight:"bold"}}>Contact</h6>
                                <p><i className="fa fa-map-marker" style={{marginRight: "5px"}}></i> {footerDetails['contactDetails'].address} </p>
                                <p><i className="fa fa-envelope" style={{marginRight: "5px"}}></i>{footerDetails['contactDetails'].email}</p>
                                {
                                    footerDetails['contactDetails'].phone.map(phoneNumber => <p key={phoneNumber.id}><i className="fa fa-phone" style={{marginRight: "5px"}}></i> {phoneNumber.value}</p>
                                    )
                                }
                            </div>
                        </div>
                    </section>

                    <hr className="my-3" />


                    <section className="p-3 pt-0">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-7 col-lg-8 text-center text-md-start">
                                <div className="p-3">
                                    © 2023 Copyright :
                                    <a className="text-decoration-none text-white" href="http://tecganah.com/"> techganah.com</a>
                                </div>
                            </div>

                            <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                                {
                                    footerDetails['socialPlatforms'].map(socialPlatform => <a href = {socialPlatform.address} target="_blank"
                                        key={socialPlatform.id} className="btn text-dark btn-outline-light btn-floating m-1" role="button">
                                        <i className={socialPlatform.icon}></i>
                                    </a>
                                    )
                                }
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className='row'>
                            <div className='col-md-6'>
                              <p><a href="https://www.freepik.com/search?author=13755764&authorSlug=storyset&format=author" target="_blank">Images by storyset</a> on Freepik</p>
                            </div>
                            <div className='col-md-6'>
                                <p>Icons created by <a href="https://www.flaticon.com/authors/dreamstale" target ="_blank" title="icons">Dreamstale,</a> <a href="https://www.flaticon.com/authors/freepik" target ="_blank" title="icons">Freepik</a> - Flaticon</p>
                            </div>
                        </div>
                    </section>
                </div>
            </footer>
        </section>
    )
}

export default Footer