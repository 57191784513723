import React from 'react';

import {useNavigate} from "react-router-dom";
import serviceNotFoundImage from '../../assets/images/serviceNotFound.svg';

function ServiceNotFound() {

	const navigate = useNavigate();

	return(		
		<div className='global-container p-5'>
			<p className='text-center' style={{fontSize: '25px'}}>Service not found !</p>
			<img src={serviceNotFoundImage}  className='d-block w-100' style={{ height: '400px' }}></img>
			<p className='text-center' style={{fontSize: '25px'}}>Please <a  onClick={() => navigate('/contact')} style={{ cursor: 'pointer', color: 'blue' }}>contact us</a> if you're looking for any other service. We'll help you !!</p>
		</div>	
	)
};

export default ServiceNotFound;