import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationComponent = ({ paginationItems, activePage, changePage, noOfItems }) => {
  return (
    <Pagination>
      {paginationItems.map(pageNum => (
        <Pagination.Item
          key={pageNum}
          onClick={() => changePage(pageNum, noOfItems)}
          active={pageNum === activePage}
        >
          {pageNum}
        </Pagination.Item>
      ))}
    </Pagination>
  );
};

export default PaginationComponent;
