import React from "react";
import "../../style/Commons/Services.css";
import { useNavigate } from 'react-router-dom';

const ServicesComponent = ({servicesList}) => {

    const navigate= useNavigate();

    const navigateTo = (service) => {
        if (service.redirectTo) {
          window.open(service.redirectTo, '_blank');
        } else {
          navigate('/services/' + service.id);
        }
    };

    return (
        <section className="services-comp-container">
            
            <div className="services-comp-card-list pt-4">
                <div className="row align-items-center mx-auto mt-3 mb-3">
                    { servicesList && servicesList.map((service) => (  
                            <div className="col-md-4 flex flex-col p-3" key={service.index}>
                                <div className="services-tile">
                                    <div
                                        className="service-card"
                                        style={{
                                        background: `url(${require(`../../assets/images/${service.image}`)}) no-repeat`,
                                        }}
                                    >
                                        
                                    </div>
                                    <div className="course-intro">
                                        <h4>{service.name}</h4>
                                        <p className="pt-2">{service.description}</p>
                                        <button className="btn btn-primary enroll" onClick={() => navigateTo(service)}>Know more</button>
                                    </div>
                                </div>
                               
                            </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ServicesComponent;