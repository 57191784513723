import React, {useEffect,useState} from "react";
import {useNavigate} from "react-router-dom";
import {  Row,Col,Button,Card,Carousel,Pagination,Dropdown} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import CarouselComponent from "../../components/commons/CarouselComponent";
import PaginationComponent from "../../components/commons/PaginationComponent";
import DropdownComponent from "../../components/commons/DropdownComponent";
import ButtonsTabComponent from "../../components/commons/ButtonsTabComponent";

function Parimarg({service})  {

  const navigate = useNavigate();

  /**Tab view of parimarg courses */  
  const [selectedModule, setSelectedModule] = useState(service["courseModules"][0],);
  const [selectedSubModule, setSelectedSubModule] = useState(selectedModule["courses"][0],);

  const handleModuleClick = (module) => {
	setSelectedModule(module);
	setSelectedSubModule((prevSelectedSubModule) => { return module["courses"][0];});
  }


  /** save selected langauage to show syllabus*/
  const handleSubModuleClick = (subModule) => {
	setSelectedSubModule(subModule);
  };

  const isMobile = window.innerWidth < 576;
  const pageSizeDesktop = 16;
  const pageSizeMobile = 5;
  const [syllabus, setSyllabus] = useState([]);
  const [paginationItems, setPaginationItems] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [noOfItems, setNoOfItems] = useState(isMobile ? pageSizeMobile : pageSizeDesktop);

  const changePage = (toPage, noOfItems) => {
    let startIndex = ((toPage - 1) * noOfItems);
    setActivePage(toPage)
    setSyllabus(selectedSubModule.topics.slice(startIndex, startIndex + noOfItems))
  }

  const updateSyllabus = () => {
    setActivePage(1);
    setSyllabus(selectedSubModule.topics.slice(0, noOfItems));
  }

  const updatePaginationItems = () => {
    const detailsCount = selectedSubModule.topics.length;
    let temp = parseInt(detailsCount / noOfItems) + ((detailsCount % noOfItems) > 0 ? 1 : 0)
    const toltalCount = temp > 1 ? temp : 0
    let arr = []
    for (let i = 1; i <= toltalCount; i++) {
      arr.push(i)
    }
    setPaginationItems(arr);
  }

  useEffect(() =>  {
    setSyllabus(selectedSubModule.topics.slice(0, noOfItems));
    updatePaginationItems();
  }, [noOfItems]);

  useEffect(() => {
    updateSyllabus();
    updatePaginationItems();
  }, [selectedSubModule, noOfItems]);

  useEffect(() => {
    const handleResize = () => {
      setNoOfItems(window.innerWidth < 576 ? 5 : 16);
    };
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


	return (
		<section className="coursemodules">
          {/**stats */}
          <div className="stats pt-5 pb-5">
            <div className="stats-container global-container">
              <div className="row">
                {service.stats.map((stat) => (
                  <div key={stat.id} className="col-sm-6 col-md-6 col-lg-3">
                    <Card className="stats-card">
                      <Card.Body className="p-3">
                        <br />
                        <Card.Img
                          src={require(`../../assets/images/${stat.image}`)}
                          className="stat-image"
                        ></Card.Img>

                        <h1 className="stat-value pt-2">{stat.count}</h1>
                        
                        <Card.Text className="stat-name">{stat.name}</Card.Text>
                        <br />
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="course-modules pt-5 text-center global-container">
            <h1 className="title coursemodules">
              <p className="section-header-title text-center p-4">
                Wide range of{" "}
                <span className='tg-blue-colour'>courses offered</span>
              </p>
            </h1>
          </div>

          {/** Courses Tab buttons */}
          <div className="global-container d-none d-md-block">
            <ButtonsTabComponent 
                selectedValue={selectedModule}
                data={service["courseModules"]}
                getParameter={'label'}
                onClickMethod={handleModuleClick}
                className="p-2 m-1"
            />
          </div>

          
          {/* Dropdown - For small devices - courses */}
          <div className=" text-center d-md-none">
            <DropdownComponent 
              selectedValue={selectedModule} 
              dropdownData={service["courseModules"]} 
              getParameter={'label'} 
              onClickMethod={handleModuleClick} 
              color={'primary'}
            />
          </div>
  

          {/* Dropdown - For small devices - programming languages*/}
          <div className="text-center d-md-none">
            <DropdownComponent 
              selectedValue={selectedSubModule} 
              dropdownData={selectedModule.courses} 
              getParameter={'courseName'} 
              onClickMethod={handleSubModuleClick} 
              color={'secondary'}
            />
          </div>

          {/**Course modules v2 */}
          {selectedModule && (
            <div className="global-container">
              <div className="row">
                {/**Course cards list v2 */}
                {selectedModule.courses && (
                  <section className="course-cards-list coursemodules p-4">
                    <div className=" course-modules">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="row d-none d-md-block">
                            {selectedModule.courses.map((course) => (
                              <div className="col-md-12" key={course.courseId}>
                                <Card
                                  className={
                                    selectedSubModule.courseId ==
                                    course.courseId
                                      ? "m-3 courses-card selected-card"
                                      : "m-3 courses-card"
                                  }
                                  role="button"
                                  onClick={() =>
                                    handleSubModuleClick(course)
                                  }
                                >
                                  <div className="row">
                                    <div className="col-md-3">
                                      <Card.Img
                                        variant="top"
                                        src={require(
                                          `../../assets/images/${course.courseImage}`,
                                        )}
                                        className="p-3"
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                        }}
                                        alt={course.courseName}
                                        key={course.courseName}
                                      />
                                    </div>
                                    <div className="col-md-8">
                                      <Card.Body>
                                        <Card.Title>
                                          {course.courseName}
                                        </Card.Title>
                                        <Card.Text>
                                          {course.courseTagLine}
                                        </Card.Text>
                                      </Card.Body>
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-8 pt-3">
                          <Card className="courses-card p-3">
                            <h1 className="text-center">
                              {selectedSubModule.courseName}
                            </h1>
                            <div className="row">
                              {syllabus.map((topic, index) => (
                                <div className="col-md-6" key={topic.name}>
                                  <Accordion alwaysOpen className="p-2">
                                    <Accordion.Item key={index} eventKey={index}>
                                      <Accordion.Header>
                                        {topic.name}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        {topic.description}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </div>
                              ))}
                            </div>

                              {/* for desktop - 16 pages */}
                              <div className='justify-content-center d-none d-md-flex'>
                                <PaginationComponent
                                  paginationItems={paginationItems}
                                  activePage={activePage}
                                  changePage={changePage}
                                  noOfItems={16}
                                />
                                </div>

                                {/* for mobile - 5 pages*/}
                              <div className='justify-content-center d-md-none d-flex'>
                                <PaginationComponent
                                  paginationItems={paginationItems}
                                  activePage={activePage}
                                  changePage={changePage}
                                  noOfItems={5}
                                />
                              </div>

                          </Card>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </div>
            </div>
          )}

      {/*


      {selectedModule && (
            
            <div className="row">
              <div>
              
                {selectedModule["batches"] && (
                  <section className="global-container pb-5">
                    <p className="section-header-title text-center">
                      Batch <span className='tg-blue-colour'>details</span>
                    </p>
                    <div className="row text-center">
                      {Object.keys(selectedModule["batches"]).map((key) => {
                        const batch = selectedModule["batches"][key];
                        return (
                          <div className="col-md-6 ps-5 pe-5 pt-4" key={batch.id}>
                            <div className="batch-details-card">
                              <div className="mb-2 pt-2">
                                <img
                                  src={require(
                                    `../../assets/images/${batch.image}`,
                                  )}
                                  alt="ongoing-batch"
                                  className="batch-details-image"
                                ></img>
                              </div>
                              <p className="batch-detail-header">
                                {batch.name}
                              </p>
                              <div className="row justify-content-center">
                                <div className="col-lg-6 col-md-6 batch-date-card">
                                  <p className="p-1">
                                    Start date: {batch.startDate}
                                  </p>
                                </div>
                                <div className="col-lg-6 col-md-6 batch-date-card">
                                  <p className="p-1">
                                    End date: {batch.endDate}
                                  </p>
                                </div>
                              </div>

                              <div className="row justify-content-center pt-3">
                                <div className="col-md-6 batch-date-card">
                                  <h5 className="p-2">
                                    <b>Instructor: </b>
                                    <a
                                      href={batch.instructorLinkedIn}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {batch.instructor}
                                    </a>
                                  </h5>
                                </div>
                                <div className="col-md-6 batch-date-card">
                                  <h5 className="p-2">
                                    <b>Mentor: </b>
                                    <a
                                      href={batch.instructorLinkedIn}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {batch.instructor}
                                    </a>
                                  </h5>
                                </div>
                              </div>
                              <div className="text-center pt-5    ">
                                <h5>
                                  <b>Course Fee:</b> Rs.{batch.courseFee}/-
                                </h5>
                              </div>
                              <div className="pt-4 pb-5">
                                <button
                                  className="btn btn-primary p-3"
                                  onClick={() => navigate("/contact/")}
                                >
                                  Register now !!
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                )}



              
                <section className="bg-white">
                  {selectedModule["instructors"] && (
                    <div className="global-container text-center ">
                      <div className="pt-5">
                        <h1 className="section-header-title">
                          Learn from our talented{" "}
                          <span className='tg-blue-colour'>
                            instructors and mentors
                          </span>
                        </h1>
                      </div>
                      <CarouselComponent data={selectedModule["instructors"]} />
                    </div>
                  )}
                </section>
              </div>
            </div>

          )}

      */}
      
        </section>


		)

}

export default Parimarg;