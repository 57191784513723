
import './App.css';
import NavigationBar from './components/navbar/NavigationBar';
import { Route, Routes } from 'react-router-dom';
import Home from './components/home/Home';
import About from './components/about/AboutUs';
import Contact from './components/contact/Contact';
import Service from './components/service/Service';
import Services from './components/services/Services';
import { useEffect } from 'react';


import Aos from 'aos';
import 'aos/dist/aos.css';
import Footer from './components/footer/Footer';

function App() {

  useEffect(() => {
    Aos.init({
      duration:2000
    })
  }, []);

  return (
   <>
    <NavigationBar/>
    <Routes>
        
        <Route path='/about' element={<About/>}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
        <Route path='/services' element={<Services/>}></Route>
        <Route path='/services/:id' element={<Service/>}></Route>
        <Route path='/' element={<Home/>}></Route>
      
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
