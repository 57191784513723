import React, {useEffect,useState} from "react";
import ServicesCardListComponent from '../../components/commons/ServicesCardListComponent';

function MachineLearning({service}) {

	return(
		<section className='grey-background p-3'>
			<div className='global-container'>
			
				<p className="section-header-title text-center">ML services <span className='tg-blue-colour'>we offer</span></p>
				
				{/*ML services offered*/}
	        	<ServicesCardListComponent cardsListData={service.servicesOffered}/>

			</div>

		</section>
		)

}


export default MachineLearning;