import React, { useEffect, useState } from "react";
import {Dropdown} from "react-bootstrap";

const DropdownComponent = ({ selectedValue, dropdownData, getParameter, onClickMethod, color }) => { 

	return (
      <div className="col-md-12 text-center p-3">
        <Dropdown>
          <Dropdown.Toggle
            variant={color}
            id="dropdown-basic"
            className="p-3 w-100"
          >
            {selectedValue[getParameter]}
          </Dropdown.Toggle>
          <Dropdown.Menu className='w-100 text-center'>
            {dropdownData.map((item, index) => ( 
             <Dropdown.Item
                key={index}
                onClick={() => onClickMethod(item)}
              >
                {item[getParameter]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
	);
};

export default DropdownComponent;