import React, {useEffect,useState} from "react";
import services from "../../assets/json/services.json";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";

import {  Row,Col,Button,Card,Carousel,Pagination,Dropdown} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import "../../style/Service.css";
import CarouselComponent from "../../components/commons/CarouselComponent";
import PaginationComponent from "../../components/commons/PaginationComponent";
import Parimarg from "../../components/service/ParimargService";
import WebAndMobileAppService from "../../components/service/WebAndMobileAppService";
import MicroServices from "../../components/service/MicroServices";
import MachineLearning from '../../components/service/MachineLearning';
import DataScience from '../../components/service/DataScience';
import ServiceNotFound from '../../components/serviceNotFound/ServiceNotFound';
import { PARIMARG, WEB_AND_APP_DEVELOPMENT, MICROSERVICES, MACHINE_LEARNING, DATA_SCIENCE } from '../../constants/ServicesConstants';


function Service() {
  let {id} = useParams();
  const [service, setService] = useState(undefined);
  const navigate = useNavigate();

  const [expandedItems, setExpandedItems] = useState([]);

  /*FAQs */
  const toggleAnswer = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(expandedItems.filter((item) => item !== index));
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  useEffect(() => {
    const service = services[id];
    setService(service);
    window.scrollTo(0,0);
  }, []);

  const navigateTo = (toUrl) => {
    navigate(toUrl);
  };

  return service != undefined ? (<
        > { /* Info , apply and Image*/ }
        <section className="pt-5 pb-5">
        <div className="global-container flex flex-col justify-center justify-between pb-5">
          <div className="row align-items-center mx-auto">
            <div
              className="col-md-7 flex flex-col justify-center p-3  rounded"
              data-aos="fade-left"
            >
              <h1 className="global-title tg-blue-colour">{service["name"]}</h1>
              <p className="mt-3"> {service["description"]}</p>
              <div className="flex flex-col items-center justify-center flex-row justify-start">
                <button
                  className="btn btn-primary rounded"
                  type="submit"
                  onClick={() => navigateTo("/contact")}
                >
                  {service["serviceButtonText"]}
                </button>
              </div>
            </div>
            <div className="col-md-5 text-center" data-aos="fade-right">
              <img
                src={require(`../../assets/images/${service["image"]}`)}
                alt=""
                style={{ height: "350px" }}
                className="section-gif d-block w-100 p-5"
              />
            </div>
          </div>
        </div>
      </section>

        { /* Parimarg */ } 
        {
            service["id"] == PARIMARG && (
              <Parimarg service={service}/>
                
            )
        }

        {/* Web and Mobile applications */ }
        {
          service["id"] == WEB_AND_APP_DEVELOPMENT && (
           <WebAndMobileAppService service={service}/>
            )
        }


        {/*Micro services*/}
        {     
          service["id"] == MICROSERVICES && (
            <MicroServices service={service}/>
            ) 
        }

        {/*Machine Learning*/}
        {
          service['id'] == MACHINE_LEARNING && (
            <MachineLearning service={service}/>
            )
        }

        {/*Data Science*/}
        {
          service['id'] == DATA_SCIENCE && (
            <DataScience service={service}/>
            )
        }

        { /*FAQs*/ }

        <section className="service-faqs p-3">
          <div className="global-container">
            <div className="faq-title">
              <h1 className="section-header-title">FAQs</h1>
            </div>
            <div className='pt-2'>
              <Accordion alwaysOpen>
                {service["faq"] &&
                  service["faq"].map((service, index) => (
                    <Accordion.Item eventKey={service.id} key={service.id}>
                      <div className="faq-question">
                        <Accordion.Header>
                          <b>{service.question}</b>
                        </Accordion.Header>
                      </div>
                      <Accordion.Body>{service.ans}</Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
            </div>
          </div>
        </section>


        <
        />
    ) : (
        <ServiceNotFound/>
    );
}

export default Service;