import { firestore } from '../firebase';
import { collection, addDoc } from "firebase/firestore";

export const addDataToFirestore = async (data) => {
    try { 
      const collectionRef = collection(firestore,'contactus');
      await addDoc(collectionRef, data)
    } catch (error) {
      console.error('Error adding data to Firestore: ', error);
    }
  };