import React, { useState, useEffect } from "react";
import { Image, Form, Row, Col, Button, FloatingLabel } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import postFormResponse from '../../controller/contactus/contact';
import contactUsImage from "../../assets/images/ContactUs.svg";
import callGif from "../../assets/images/telephoneIcon.png";
import mailGif from "../../assets/images/gmailIcon.png";
import locationIcon from "../../assets/images/location.png";
import linkedIn from "../../assets/images/linkedin.png";
import "../../style/Contact.css";
import homePageDetails from "../../assets/json/Home.json";
import { addDataToFirestore } from "../../controllers/contactus";

function Contact() {
  const [validated, setValidated] = useState(false);

   const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        service: "",
        message: ""
    });

   
  const handleFormSubmit = (event) => {
    console.log('in handleFormSubmit');
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      console.log("Form Invalid")
      setValidated(true);
      event.stopPropagation();
    } else {
      addDataToFirestore(data).then((result) => {
            console.log("Inside Post Call")
            toast.success('Our Team will Contact you shortly \n Thank you for Contacting Techganah', {
                position :"top-center",
                autoClose : 5000,
                hideProgressBar : false,
                newestOnTop : false,
                closeOnClick : true,
                rtl : false,
                draggable : false,
                theme : "colored" 
            }
            )
        }).catch((err) => {
            console.log("Inside Catch")
            toast.error('Something went wrong', {
                position :"top-center",
                autoClose : 5000,
                hideProgressBar : false,
                newestOnTop : false,
                closeOnClick : true,
                rtl : false,
                draggable : false,
                theme : "colored" 
            }
            )
        });
      setValidated(false);
      clearForm();
    };
  }

    function clearForm() {
      setData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        service: "",
        message: ""
      });
      

    };


  function handleFieldValueChage(e) {
    const { name, value } = e.target
    setData(prevValue => {
        return {
            ...prevValue,
            [name]: value
        }
    })
}
  
  function clearForm() {
    setData(
            {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                service: "",
                message: ""
            }
        )
  }

  return (
    <>
      <section>
      <ToastContainer />
        <div className="global-container">
          <p className="section-header-title text-center pt-5">
            Help us <span className='tg-blue-colour'>serve you better</span>
          </p>
        </div>
      </section>

      {/* Contact Us 2 */}
      <section>
        <div className="global-container">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12 text-center justify-content-center align-center">
              <Image
                src={contactUsImage}
                className="d-block pt-5 w-100"
                style={{ height: "500px" }}
              />
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="row pt-5">
                <div className="col-lg-3 col-md-6 col-sm-12 pe-md-4 contactus-icon">
                  <i className="fa fa-phone " style={{ color: "#12408B" }}></i>
                </div>
                <div className="col-lg-9 col-md-6  col-sm-12 ps-md-5">
                  <p className="text-lg-start text-md-start pt-2">
                    <b>Contact Number</b>
                  </p>
                  <p className="text-lg-start text-md-start">+91 9502296604</p>
                </div>
              </div>
              <div className="row  pt-5">
                <div className="col-lg-3 col-md-6 col-sm-12 pe-md-4 contactus-icon">
                  <i className="fa fa-linkedin " style={{ color: "#12408B" }}></i>
                </div>
                <div className="col-lg-9 col-md-6  col-sm-12 ps-md-5">
                  <p className="text-lg-start text-md-start pl-md-4 pt-2">
                    <b>LinkedIn</b>
                  </p>
                  <a href='https://www.linkedin.com/company/techganah-info-solutions-private-limited/'><p className="text-lg-start text-md-start">/techganah-info-solutions</p></a>
                </div>
              </div>
              <div className="row  pt-5">
                <div className="col-lg-3 col-md-6 col-sm-12 pe-md-4 contactus-icon">
                  <i className="fa fa-envelope " style={{ color: "#12408B" }}></i>
                </div>
                <div className="col-lg-9 col-md-6  col-sm-12 ps-md-5">
                  <p className="text-lg-start text-md-start pl-md-4 pt-2">
                    <b>Mail ID</b>
                  </p>
                  <p className="text-lg-start text-md-start">
                    contact@techganah.com
                  </p>
                </div>
              </div>
              <div className="row  pt-5">
                <div className="col-lg-3 col-md-6 col-sm-12 pe-md-4 contactus-icon">
                  <i className="fa fa-map-marker " style={{ color: "#12408B" }}></i>
                </div>
                <div className="col-lg-9 col-md-6  col-sm-12 ps-md-5">
                  <p className="text-lg-start text-md-start pl-md-4 pt-2">
                    <b>Address</b>
                  </p>
                  <p className="text-lg-start text-md-start">Khairathabad, Hyderabad, India</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*Form & Map*/}
      <section>
        <div className="global-container p-2">
          <div className="row pt-2 pb-4">
            {/* Form */}
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="form">
                <h2 className="pb-1">
                  <b>
                    Have queries ?{" "}
                    <span className='tg-blue-colour'>Contact us !</span>
                  </b>
                </h2>
                <div className="">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleFormSubmit}
                  >
                    <div className="form-border">
                      <div className="row">
                        
                        <div className='col-lg-6 col-md-12'>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="First Name *"
                            className="mb-4"
                          >
                            <Form.Control
                              className="form-element"
                              required
                              type="text"
                              placeholder="First Name"
                              name="firstName"
                              onChange={(event) => handleFieldValueChage(event)}
                              value={data.firstName}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid First Name.
                            </Form.Control.Feedback>
                          </FloatingLabel>
                        </div>
                        

                        <div className='col-lg-6 col-md-12'>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Last Name"
                            className="mb-4"
                          >
                            <Form.Control
                              className="form-element"
                              type="name"
                              placeholder="Last Name"
                              name="lastName"
                              onChange={(event) => handleFieldValueChage(event)}
                              value={data.lastName}
                            />
                          </FloatingLabel>
                        </div>
                       
                      </div>

                      <FloatingLabel
                        controlId="floatingInput"
                        label="Email Address *"
                        className="mb-4"
                        key={3}
                      >
                        <Form.Control
                          className="form-element"
                          required
                          type="email"
                          placeholder="Email Address"
                          name="email"
                          onChange={(event) => handleFieldValueChage(event)}
                          value={data.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid Email ID.
                        </Form.Control.Feedback>
                      </FloatingLabel>

                      <FloatingLabel
                        controlId="floatingInput"
                        label="Phone *"
                        className="mb-4"
                        key={4}
                      >
                        <Form.Control
                          className="form-element"
                          required
                          type="tel"
                          pattern="[0-9]{10}"
                          placeholder="Phone"
                          name="phone"
                          onChange={(event) => handleFieldValueChage(event)}
                          value={data.phone}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid mobile number.
                        </Form.Control.Feedback>
                      </FloatingLabel>

                      <FloatingLabel className="mb-4" key={5}>
                        <Form.Select
                          className="form-element"
                          required
                          placeholder="Select a service"
                          defaultValue="None"
                          name="service"
                          onChange={(event) => handleFieldValueChage(event)}
                          

                        >
                          <option value="">Select a service</option>
                          {homePageDetails['servicesShortDescription'] && homePageDetails['servicesShortDescription'].map((serviceData) => (
                            <option value={serviceData.value} key={serviceData.value}>
                              {serviceData.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please select a service.
                        </Form.Control.Feedback>
                      </FloatingLabel>

                      <FloatingLabel
                        controlId="floatingTextarea2"
                        label="Message"
                        className="mb-4"
                        key={6}
                      >
                        <Form.Control
                          className="form-element"
                          required
                          as="textarea"
                          placeholder="Leave a comment here"
                          style={{ height: "200px" }}
                          name="message"
                          onChange={(event) => handleFieldValueChage(event)}
                          value={data.message}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please add your message / query.
                        </Form.Control.Feedback>
                      </FloatingLabel>

                      <Button
                        variant="primary"
                        type="submit"
                        style={{ width: "100%", height: "50px" }}
                      >
                        <p className="p-1" style={{ fontSize: "20px" }}>
                          Submit
                        </p>
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>

            {/*Map*/}
            <div className="col-lg-5 col-md-12 col-sm-12 map-location p-3">
              <iframe
                title="location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.039830134509!2d78.45872317516545!3d17.409876083481105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97a5d2754599%3A0x62831875688a7e0c!2sTechganah%20Info%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1703884916121!5m2!1sen!2sin"
                width="100%"
                height="100%"
                allowfullscreen=""
                className=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;