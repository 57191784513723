import React from "react";
import { Image, Card } from "react-bootstrap";
import homePageDetails from "../../assets/json/Home.json";
import servicesImage from "../../assets/images/web-application.svg";
import { useNavigate } from 'react-router-dom';
import "../../style/Service.css";
import ServicesComponent from "../../components/commons/ServicesComponent";

function Services() {

    const navigate = useNavigate();

    return(
        <>
            {/*services title and gif */}
            <section style={{background: '#fcf9f9'}}>
                <div className="global-container p-3 flex flex-col">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="section-title text-center mb-4 pb-2">
                                <h1 className="global-title tg-blue-colour mb-4" data-aos='fade-right'>{"Our Services"}</h1>
                                <p className="tag-line" data-aos='fade-left'>In an era of transformation, creativity and adaptability are paramount for any organization to sustain. Growth is the result of forces working together. We will be your best “together”. Use our services and Prepare for an enduring success.</p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="">
                                <Image 
                                className="section-gif d-block w-100"
                                rounded
                                src={servicesImage}
                                data-aos="fade-left"
                                ></Image>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Services list*/}
            <section className="p-3">
                <div className='global-container'>
                    <div>
                        <p className="section-header-title text-center">Explore our wide <span className='tg-blue-colour'>range of services</span></p>
                    </div>
                    <div className="section-title text-center mb-4">
                            <p className="text-muted para-desc mx-auto mb-0">Whether you are a 6-month-old Start-up or a 30-year-old Titan looking for a solution for complex challenges, with a team of highly skilled tech professionals and strategic alliances with technology merchants, Techganah offers a wide range of services designed to help your business overgrow more efficiently.</p>
                        </div>
                    <ServicesComponent servicesList={homePageDetails["servicesShortDescription"]}/>
                </div>
            </section>

        </>
    );
}

export default Services;