import React from "react";

const ServicesCardListComponent = ({cardsListData}) => {

	return (
		<div className="services-offered row pt-1 pl-2 pr-2">
    		{cardsListData.map((cardData, index) => (
                <div className="services-offered-card pt-4 pb-4  col-md-4 justify-content-center" key={index}>
                    <div className="text-center">
                        <img src={require(`../../assets/images/${cardData.image}`)} alt="service" data-aos="fade-right" style={{width: '250px', height: '250px'}}></img>
                    </div>
                    <div className="course-card services-offered-card-heading p-2 text-center " data-aos="fade-left"> 
                        {cardData.name}
                    </div>
                    <div>
                        <div>
                            <p className="text-center p-3" data-aos="fade-left">{cardData.description}</p>
                        </div>
                    </div>
                </div>
            ))}
    	</div>
	)
};

export default ServicesCardListComponent;