import React, { useState } from 'react';
import { Navbar, Nav, Container, Offcanvas, Button, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../assets/images/logo.svg'
import "../../style/NavigationBar.css";

function MyNavbar() {
  const navigate = useNavigate();

     const [activeNav, setActiveNav] = useState();
     const [showOffCanvas, setShowOffCanvas] = useState(false);

     const navigateTo = (toUrl) => {
         const activePage = toUrl.substring(1, toUrl.length);
         setActiveNav(activePage)
         setShowOffCanvas(false);
         navigate(toUrl);
       };

  return (

    <Navbar key={'lg'} expand={'lg'} className="navbar-nav">
      <Container fluid>
        <Navbar.Brand href="#">
          <Image
            src={logo}
            className="logo-image"
            rounded
            onClick={()=>{navigateTo('/')}}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${'ls'}`} onClick={() => setShowOffCanvas(true)}/>
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${'ls'}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${'ls'}`}
          placement="end"
          show={showOffCanvas}
          onHide={() => setShowOffCanvas(false)}
        >
          <Offcanvas.Header closeButton className="offcanvas-head">
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'ls'}`}>
              TechGanah
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3 offcanvas-body">
              <Nav.Link className={`navbar-item ${activeNav == '' ? 'nav-active' : ''}`} onClick={()=>{navigateTo('/')}}>HOME</Nav.Link>
                <Nav.Link className={`navbar-item ${activeNav == 'about' ? 'nav-active' : ''}`} onClick={()=>{navigateTo('/about')}}>ABOUT US</Nav.Link>
                <Nav.Link className={`navbar-item ${activeNav == 'services' ? 'nav-active' : ''}`} onClick={()=>{navigateTo('/services')}}>SERVICES</Nav.Link>
                <Nav.Link className={`navbar-item ${activeNav == 'contact' ? 'nav-active' : ''}`} onClick={()=>{navigateTo('/contact')}}>CONTACT US</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;
